.tooltip__wrapper {
  text-align: center;
}

.tooltip__wrapper h3 {
  font-size: 16px;
  color: var(--color-font-black);
  margin-top: 24px;
  margin-bottom: 24px;
}

.tooltip__wrapper .qrcode {
  margin-bottom: 15px;
}

.tooltip__wrapper p {
  font-size: 14px;
  color: var(--color-font-black-60);
  margin-bottom: 20px;
}
