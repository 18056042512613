.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  padding-inline: 2%;
  padding-block: 8px;
  position: relative;
  color: var(--color-white);

  .logo {
    width: 15%;
  }

  .logo,
  .login-wrapper {
    flex-shrink: 0;
  }

  .header-info {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .login-wrapper {
    .registration {
      font-size: 14px;
      padding: 8px 10px;
      background: linear-gradient(179deg, #ff1346 2%, #ff3e29 97%);
      box-shadow: inset 0px -1px 2px 0px rgba(255, 255, 255, 0.6),
        inset 0px -2px 10px 0px rgba(255, 255, 255, 0.5);
      border: none;
      border-radius: 30px;
      cursor: pointer;
      font-weight: 600;
      letter-spacing: 0px;
      color: var(--color-white);

      &:disabled {
        cursor: not-allowed;
      }

      &:hover {
        background: linear-gradient(179deg, #ff3e29 2%, #ff1346 97%);
      }
    }

    .login-inner {
      display: flex;
      align-items: center;
      font-size: 12px;
      gap: 12px;
    }
  }
}

/* 通用header */
.base-header {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}