.PageHead__wrapper {
  background-color: var(--color-white);
  flex-shrink: 0;
  display: block;
  margin: 0;
  width: 100%;
  min-width: 1080px;

  .PageHead {
    padding: 15px 30px;
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .PageHead_suffix {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: nowrap;
      flex-shrink: 0;
      .link_btn {
        margin-left: 17px;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: var(--color-font-black-60);
        &:hover {
          font-weight: 600;
        }
        span {
          vertical-align: top;
        }
      }
      .icon {
        cursor: pointer;
        vertical-align: middle;
        width: 24px;
        height: 24px;
      }
    }
  }
}
