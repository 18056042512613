.kf {
  position: fixed;
  bottom: 20px;
  right: 40px;
  z-index: 2;
  background-color: var(--color-white);
  padding-inline: 7px;
  padding-top: 12px;
  padding-bottom: 8px;
  border-radius: 22px;
  color: var(--color-font-black);
  cursor: pointer;
  z-index: 99;
  box-shadow: 0px 20px 30px 0px rgba(60, 63, 88, 0.15);
  font-size: 16px;

  &:hover {
    color: initial;
  }

  .kf-icon {
    display: block;
    width: 18px;
    height: 18px;
    margin-bottom: 7px;
    color: var(--color-primary);
  }
}
.kf-relative {
  position: relative;
  bottom: 0;
  right: 0;
}

.kf-btn-wrap {
  display: flex;
  margin: 5px 0 10px;
}
.kf-btn-name {
  max-width: 2em;
}