.kf-en {
  padding-inline: 6px;
  padding-block: 16px;
  background-color: var(--color-white);
  border-radius: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .kf-en-item {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .kf-en-icon {
      width: 24px;
      height: 24px;
      padding: 4px;
      color: var(--color-primary);
    }
    .kf-en-text {
      display: block;
      color: var(--color-font-black);
    }

    & + .kf-en-item {
      border-top: 1px solid #c2c2c2;
      padding-top: 10px;
      margin-top: 10px;
    }
  }
}
