.conference-container {
  .logo {
    width: 282px;
    height: 62px;
    margin-right: 42px;
  }

  .login {
    color: var(--color-white);
    border: none;
    background: transparent;
    cursor: pointer;
  }
}

.right {
  position: fixed;
  right: 25px;
  bottom: 66px;
  color: var(--color-font-black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;
  gap: 20px;

  .qrcode {
    background-color: var(--color-white);
    border-radius: 10px;
    padding: 9px;
    text-align: center;
    box-shadow: 0px 0 20px 0px rgba(60, 63, 88, 0.15);

    span {
      font-size: 16px;
    }

    .qrcode-image-wrapper {
      width: 88px;
      height: 88px;
      margin: 0 auto 6px;
      position: relative;
    }
  }

  .conference_notice {
    font-size: 16px;
    border-radius: 50px;
    padding-block: 12px;
    padding-inline: 14px;
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    box-shadow: 0px 0 20px 0px rgba(60, 63, 88, 0.15);
    row-gap: 8px;

    .notice-icon {
      width: 18px;
      height: 18px;
      margin-bottom: 5px;
      color: var(--color-primary);
    }
    .notice-text {
      display: inline-block;
      width: 2em;
      line-height: 1.5;
    }
    &.conference_notice-en {
      width: 100px;
      border-radius: 20px;
      padding-block: 16px;
      padding-inline: 6px;
      background-color: var(--color-white);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      box-shadow: 0px 0 20px 0px rgba(60, 63, 88, 0.15);
      row-gap: 7px;

      .notice-text {
        display: inline;
        writing-mode: revert;
        text-align: center;
      }
    }
  }

  .top {
    padding: 13px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0 20px 0px rgba(60, 63, 88, 0.15);
    background-color: var(--color-white);
    box-sizing: border-box;
    cursor: pointer;
    .top-icon {
      width: 18px;
      height: 18px;
      color: var(--color-primary);
    }
  }
}