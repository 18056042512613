/* 中文版标题 */
.loginTitle {
  font-weight: 600;
  font-size: 24px;
  color: var(--color-font-black);
  text-align: center;
  margin-bottom: 30px;
}
/* 中文版tip */
.loginTip {
  font-size: 16px;
  color: var(--color-font-black);
  text-align: center;
  margin-top: 20px;
}
/* 中文版小程序失效刷新 */
.expired {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  color: var(--color-font-black-60);
  background-color: var(--color-background-3);
  margin: 0 auto;
  cursor: pointer;
  .icon {
    width: 32px;
    height: 32px;
    color: var(--color-font-black-60);
    font-size: 32px;
    margin-bottom: 30px;
  }
}
/* 中文版小程序码 */
.scan-wave {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  margin: 0 auto;

  .qrCode {
    width: 100%;
    height: 100%;
  }

  .wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30%;
    animation: scan 2s infinite;
    animation-iteration-count: 4;
  }
}

@keyframes scan {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(300%);
  }
}

/* 中文版医院下拉选择 */
.addHospital {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .blue {
    color: var(--color-primary);
    cursor: pointer;
  }
}
/* 中文版用户信息提交 */
.submit {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 中文版用户信息展示 */
.photo {
  border-radius: 50%;
  overflow: hidden;
  width: 24px;
  height: 24px;
}
.name {
  font-size: 14px;
  color: var(--color-font-black-60);
}
.icon {
  vertical-align: middle;
  color: #646566;
}

/* 英文版登录弹出层 */
.enLoginModuleWrapper {
  h3 {
    font-size: 30px;
    font-weight: 350;
    line-height: 30px;
    color: #3d3d3d;
    margin-top: 28px;
    margin-bottom: 32px;
    text-align: center;
  }
  .codeBtn {
    background: transparent;
    border: none;
    color: var(--color-primary);
    cursor: pointer;
    padding-inline: 12px;
  }
  .checkbox {
    justify-self: self-start;
    margin-bottom: 14px;
    .link {
      color: var(--color-primary);
      &:hover {
        color: var(--color-primary-hover);
      }
    }
  }
  .submit {
    width: 248px;
    margin: 0 auto;
  }
}
.error {
  color: var(--color-danger-font-1);
  font-size: 14px;
  a {
    color: var(--color-primary);
  }
}
