
.nav_item {
    padding: 0 20px;
    font-size: clamp(16px, 2vw, 18px);
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    color: var(--color-font-black);
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: color 0.3s ease;
    white-space: nowrap;
    overflow: hidden;

    .nav_item_text {
      color: var(--color-font-black-70);
      cursor: pointer;
    }
    &.active .nav_item_text {
      color: var(--color-primary);
    }
    &:hover .nav_item_text {
      color: var(--color-primary);
    }
  }