/* 中文版 */
.page_foot {
  padding: 50px 0 20px;
  background-color: var(--color-white);
  flex-shrink: 0;
  width: 100%;

  .page_foot__content {
    margin: 0 auto;

    .page_foot__link {
      width: 60vw;
      max-width: 1024px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      list-style: none;
    }

    .page_foot__info {
      margin-top: 15px;
      color: var(--color-font-black-45);
      .linkICP:hover {
        color: var(--color-primary);
      }
    }
  }
}
/* 版权声明与备案号信息样式 */
.page_foot__info {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
/* 英文版 */
.en-footer {
  padding-top: 30px;
  padding-bottom: 16px;
  background-color: var(--color-white);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .conferenceEn {
    width: 60vw;
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 12px;

    .bottomLogo {
      width: 40%;
    }

    .logo {
      width: 20%;
    }

    .foot_info {
      width: 40%;
      text-align: right;
      word-break: break-word;
      font-size: 22px;
      font-weight: 600;
      line-height: 32px;
      color: var(--color-white);
    }
  }
  .page_foot__info {
    color: rgba(255, 255, 255, 0.4);

    .linkICP:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
}
