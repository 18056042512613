.address-wrapper {
  display: flex;
  align-items: center;
  white-space: nowrap;

  &.en {
    /* 与UI沟通后移除英文字间距，会导致与设计稿不一致问题 */
    letter-spacing: 0;
  }

  .title {
    font-weight: 600;
    color: var(--color-white);
    letter-spacing: 0px;
    margin-right: 10px;
    font-size: 14px;
    /* 禁止换行 */
  }

  .subTitle {
    color: #240dbc;
    background-color: #ffffff;
    border-radius: 5px;
    position: relative; /* 为了定位伪元素 */
    padding: 3px 10px; /* 增加一些内边距以便更好地展示三角形 */
    font-size: 12px;

    .email {
      color: inherit;
      text-decoration: none;

    }
  }
}