/* nav支撑 */
/* .navPlaceholder {

  height: 110px;
  flex-shrink: 0;
} */
/* 蒙层样式 */
#mask {
  position: fixed;
  top: 110px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 997;
  -webkit-backdrop-filter: blur(4px);
  -ms-backdrop-filter: blur(4px);
  -moz-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}
/* 导航容器 */
.PageHead__nav_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .PageHead__nav {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}