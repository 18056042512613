

.PageHead__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    height: 40px;
    width: 100%;
    flex: 1;
    .logo {
      vertical-align: top;
      width: fit-content;
      height: 40px;
    }
  }