.float_wrapper {
  position: absolute;
  margin-top: 110px;
  width: 100%;
  padding-bottom: 20px;
  min-height: 520px;
  top: 0;
  left: 0;
  z-index: 999;
  background-position-x: center;
  background-repeat: no-repeat;
  cursor: default;
  &.upwards {
    background-size: cover;
  }
  a {
    text-align: center;
    padding: 6px 102px;
    font-size: 14px;
    line-height: 21px;
    background-color: var(--color-primary);
    color: var(--color-white);
    cursor: pointer;
    border: none;
    border: 2px solid var(--color-white);
    border-radius: 5px;
    display: inline-block;

    &:hover {
      background-color: var(--color-primary);
      color: var(--color-white);
    }
  }
}

.center_review_wrapper {
    text-align: center;
    margin: 0 auto;
    background-color: var(--color-white);
    .banner {
      width: 50%;
      padding: 30px 0 10px;
    }
    .intro_wrapper {
      white-space: pre-wrap;
      flex-wrap: wrap;
      line-height: 25px;
      color: var(--color-font-black-85);
      font-size: 14px;
      width: 50%;
      margin: 10px auto 0;
      padding: 20px 40px 25px;
      border-radius: 10px;
      background-color: #fffdf4;
    }
    .qrcode_box {
      margin-top: 25px;
    }
    .qrcode {
      width: 100px;
      height: 100px;
    }
    .tip {
      margin-top: 10px;
      color: #474748;
      font-weight: normal;
    }
    .btn {
      margin: 15px auto 20px;
      border: none;
      border-radius: 5px;
      background-color: var(--color-primary);
      color: var(--color-white);
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      display: inline-block;
    }
  }