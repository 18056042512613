.page_foot__link_item {
  h3 {
    font-size: 18px;
    font-weight: 600;
    color: var(--color-font-black);
    line-height: 21px;
  }
  .items_wrapper .link {
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
    display: block;
    line-height: 21px;
    cursor: pointer;
    color: var(--color-font-black-70);
  }
}
