.float_wrapper {
  position: absolute;
  margin-top: 110px;
  width: 100%;
  padding-bottom: 20px;
  min-height: 520px;
  top: 0;
  left: 0;
  z-index: 999;
  background-position-x: center;
  background-repeat: no-repeat;
  cursor: default;
  &.upwards {
    background-size: cover;
  }
  a {
    text-align: center;
    padding: 6px 102px;
    font-size: 14px;
    line-height: 21px;
    background-color: var(--color-primary);
    color: var(--color-white);
    cursor: pointer;
    border: none;
    border: 2px solid var(--color-white);
    border-radius: 5px;
    display: inline-block;

    &:hover {
      background-color: var(--color-primary);
      color: var(--color-white);
    }
  }
}

.btnGroup {
  padding-top: 30px;
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
}

.upwards_wrapper {
  text-align: center;
  margin: 0 auto;
  .upLogo {
    padding: 30px 0 10px;
  }
  .intro {
    white-space: pre-wrap;
    flex-wrap: wrap;
    line-height: 25px;
    color: var(--color-font-black-85);
    font-size: 14px;
    width: 50%;
    margin: 0 auto;
  }
  h2 {
    padding: 10px 0;
    color: var(--color-font-black);
    font-size: 16px;
  }
}

.up_list {
  width: 50%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 0 auto;
  li {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 14px;
      color: var(--color-font-black);
      margin: 0;
      padding: 0;
      line-height: 25px;
    }
    p {
      white-space: pre-wrap;
      font-size: 14px;
      line-height: 25px;
      color: var(--color-font-black);
      font-weight: 400;
      text-align: center;
    }
  }
}

.upImage_wrapper {
  flex-shrink: 0;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  height: 100px;

  .upImage {
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    object-fit: contain;
    border-radius: 8px;
  }
}
